<template>
  <b-card
    title="Bug Report"
  >
    <validation-observer ref="bugReportForm">
      <b-row>
        <b-col md="6">
          <!-- name -->
          <b-form-group
            label="Name"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="userName"
                :state="errors.length > 0 ? false:null"
                name="name"
                placeholder="Enter name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <!-- email -->
          <b-form-group
            label="Email"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-input
                id="email"
                v-model="userEmail"
                :state="errors.length > 0 ? false:null"
                name="email"
                placeholder="Enter email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <!-- title -->
          <b-form-group
            label="Title"
            label-for="title"
          >
            <validation-provider
              #default="{ errors }"
              name="Title"
              rules="required"
            >
              <b-form-input
                id="title"
                v-model="title"
                :state="errors.length > 0 ? false:null"
                name="title"
                placeholder="Enter title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <!-- report type -->
          <b-form-group
            label="Report Type"
            label-for="reportType"
          >
            <validation-provider
              #default="{ errors }"
              name="Report Type"
              rules="required"
            >
              <b-form-select
                id="reportType"
                v-model="reportType"
                :state="errors.length > 0 ? false:null"
                name="reportType"
                placeholder="Enter report type"
                :options="options"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <!-- Description -->
          <b-form-group
            label="Description"
            label-for="description"
          >
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <b-form-textarea
                id="description"
                v-model="description"
                :state="errors.length > 0 ? false:null"
                name="description"
                placeholder="Enter description"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <!-- file -->
          <b-form-group
            label="Files"
            label-for="files"
          >
            <validation-provider
              #default="{ errors }"
              name="File"
              rules="sizeLimit"
            >
              <b-form-file
                id="files"
                v-model="files"
                :state="errors.length > 0 ? false:null"
                name="files"
                :multiple="true"
                placeholder="Upload relevent files"
                drop-placeholder="Drop files here"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end w-100">
        <b-button
          variant="primary"
          @click="handleReportBug"
        >
          <span v-if="loading !== true">Report Bug</span>
          <b-spinner
            v-else
            variant="white"
          />
        </b-button>
      </div>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required, email } from '@validations'

/* eslint-disable no-param-reassign */
import Ripple from 'vue-ripple-directive'
/* eslint-disable no-return-assign */
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  directives: { Ripple },
  data() {
    return {
      userName: null,
      userEmail: null,
      title: null,
      reportType: null,
      description: null,
      files: null,
      // trivial
      loading: null,
      options: ['Feature', 'Enhancement', 'Bug'],

      // validators
      email,
      required,
      sizeLimit: extend('sizeLimit', {
        validate: this.checkSize,
        message: 'Total files size exceeding limit (20mb)',
      }),
    }
  },
  methods: {
    //   method to check size of files in an array
    checkSize(files) {
      let size = 0
      for (let i = 0; i < files.length; i += 1) {
        size += files[i].size
      }
      if (size > 20971520) {
        return false
      }
      return true
    },
    async handleReportBug() {
      if (!await this.$refs.bugReportForm.validate()) return
      this.showLoading()
      const formData = new FormData()
      this.files.forEach(file => formData.append(file.name, file))
      formData.append('name', this.userName)
      formData.append('email', this.userEmail)
      formData.append('title', this.title)
      formData.append('reportedFrom', 'admin')
      formData.append('reportType', this.reportType)
      formData.append('description', this.description)
      this.$http.post('/bugReports', formData).then(() => {
        this.$bvToast.toast('Report submitted successfully', {
          title: 'Successful',
          variant: 'success',
          solid: true,
        })
        this.$router.push('/')
      }).catch(this.handleError).finally(this.closeLoading)
    },
  },
}
</script>

<style lang="scss" scoped>
main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr 1fr;
}
</style>
